@mixin cssReset {
  &, &::before, &::after,
  *, *::before, *::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0 ;
    line-height: 1;
  }
}

// Target the .modal-container
@mixin resetModalContainer {
  transform: none;
  border-radius: 0;
  background: none;
  box-shadow: none;
  width: initial;
  max-width: initial;

  .modal-header {
    border-bottom: none;
  }

  .modal-footer {
    border-top: none;
  }

  .content {
    padding-block: 0;
  }
}
