// Our custom breakpoints - in addition to the bootstrap 4 ones defined in bootstrap and our EBreakpoint enum file
// Try to use the bootstrap breakpoints before resorting to custom ones
// If these are ever changed / added to / removed from, be sure to also update the karma-viewport config (karma.conf)
// to match so that tests are kept in sync with the app's config

// Theme Breakpoints
$phone-max-width: 415px;
$phone-sm-width: 550px;
$phone-md-width: 600px;
$phone-lg-width: 720px;
$breakpoint-lg: 850px;
$navbarBreakpoint-md: 700px;
$navbarBreakpoint-lg: 850px;
$navbarBreakpoint-xl: 950px;
$tablet-max-width: 1105px;
$vehicleListBreakpoint-md: 664px;
$vehicleListBreakpoint-sm: 440px;
$thirdColumnBreakpoint: 1550px;

// Bootstrap Breakpoints
$bs-breakpoint-xs: 0;
$bs-breakpoint-sm: 576px;
$bs-breakpoint-md: 768px;
$bs-breakpoint-lg: 992px;
$bs-breakpoint-xl: 1200px;
$bs-breakpoint-xxl: 1400px;

//Global Border
$border-global: 1px solid rgb(212, 214, 215);

$border-radius-global: 6px;


//Theme Font

$font-family-lato: 'Lato';
body {
  font-family: $font-family-lato;
}

//Theme colors
$white: rgba(255, 255, 255, 1);
$black: rgba(0, 0, 0, 1);

$gray-0: rgba(248, 250, 251, 1);
$gray-5: rgba(240, 243, 245, 1);
$gray-10: rgba(229, 234, 235, 1);
$gray-20: rgba(212, 214, 215, 1);
$gray-30: rgba(189, 191, 193, 1);
$gray-40: rgba(165, 167, 169, 1);
$gray-50: rgba(116, 118, 118, 1);
$gray-60: rgba(64, 65, 66, 1);
$gray-69: rgba(36, 36, 37, .5);
$gray-70: rgba(36, 36, 37, 1);
$gray-80: rgba(25, 25, 26, 1);

$blue-0: rgba(242, 248, 255, 1);
$blue-5: rgba(209, 229, 253, 1);
$blue-10: rgba(177, 211, 252, 1);
$blue-20: rgba(113, 173, 245, 1);
$blue-30: rgba(53, 135, 234, 1);
$blue-40: rgba(9, 104, 217, 1);
$blue-50: rgba(0, 89, 192, 1);
$blue-60: rgba(0, 75, 162, 1);
$blue-70: rgba(0, 60, 128, 1);
$blue-72: rgba(6, 51, 114, 1);
$blue-80: rgba(10, 37, 78, 1);
$blue-90: rgba(10, 23, 51, 1);

$green-0: rgba(239, 255, 241, 1);
$green-5: rgba(207, 250, 211, 1);
$green-10: rgba(175, 246, 182, 1);
$green-20: rgba(106, 222, 126, 1);
$green-30: rgba(48, 183, 85, 1);
$green-40: rgba(12, 138, 62, 1);
$green-50: rgba(0, 114, 56, 1);
$green-60: rgba(1, 73, 41, 1);
$green-70: rgba(1, 64, 37, 1);

$yellow-0: rgba(255, 249, 237, 1);
$yellow-5: rgba(253, 235, 199, 1);
$yellow-10: rgba(252, 221, 161, 1);
$yellow-20: rgba(245, 189, 85, 1);
$yellow-30: rgba(234, 156, 17, 1);
$yellow-40: rgba(217, 135, 0, 1);
$yellow-50: rgba(192, 114, 0, 1);
$yellow-60: rgba(162, 91, 0, 1);
$yellow-70: rgba(128, 68, 0, 1);

$orange-0: rgba(255, 243, 238, 1);
$orange-5: rgba(254, 221, 208, 1);
$orange-10: rgba(255, 197, 173, 1);
$orange-20: rgba(253, 147, 106, 1);
$orange-30: rgba(245, 92, 35, 1);
$orange-40: rgba(216, 61, 8, 1);
$orange-50: rgba(169, 40, 0, 1);
$orange-60: rgba(135, 30, 0, 1);
$orange-70: rgba(102, 20, 0, 1);

$red-0: rgba(255, 237, 240, 1);
$red-5: rgba(253, 205, 212, 1);
$red-10: rgba(251, 174, 184, 1);
$red-20: rgba(241, 109, 128, 1);
$red-30: rgba(223, 58, 85, 1);
$red-40: rgba(205, 9, 45, 1);
$red-50: rgba(174, 0, 35, 1);
$red-60: rgba(133, 0, 28, 1);
$red-70: rgba(89, 0, 19, 1);
$purple-0: rgba(245, 238, 255, 1);

$purple-5: rgba(226, 208, 253, 1);
$purple-10: rgba(207, 178, 252, 1);
$purple-20: rgba(168, 117, 245, 1);
$purple-30: rgba(134, 61, 234, 1);
$purple-40: rgba(108, 19, 217, 1);
$purple-50: rgba(90, 0, 192, 1);
$purple-60: rgba(77, 0, 162, 1);
$purple-70: rgba(62, 0, 128, 1);

/* override bootstrap variables below here */

$success: $green-50; //Input success,
$error: $red-40; //Input error,
$warning : $yellow-30; //Input warning,

$btn-white-space: nowrap;

// Enable negative margins
$enable-negative-margins: true;
