@import '../../../sass/themes.scss';

$mobile-breakpoint: 1024px;
$header-bar-height: 64px;
$impersonating-bar-height: 48px;

:host {
  display: flex;
  flex-direction: column;
  height: calc(100vh - $header-bar-height);

  &.impersonating {
    height: calc(100vh - $header-bar-height - $impersonating-bar-height);
  }

  .filters {
    display: flex;
    width: 100%;
    padding: 1em;
    align-items: center;
    background: $white;
    box-shadow: 0px -1px 0px 0px #E5EAEB inset;

    #athleticsToggle {
      white-space: nowrap;
    }

    .dropdown-wrapper {
      margin-right: -24px;
    }

    .filter-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 16px;

      .tabs {
        display: flex;
        gap: 12px;

        @media (max-width: 825px) {
          flex-wrap: nowrap;
          width: 100%;
        }

        .tab-wrapper {
          white-space: nowrap;

          &:hover {
            background: $gray-10;
          }
        }

        .tabBorder {
          outline: 2px solid $blue-40;
          outline-offset: -2px;
          color: $blue-40 !important;
          background-color: $white !important;

          .tab-chevron {
            color: $blue-40 !important;
          }
        }

        .small-medium-text {
          display: flex;
          padding: 8px 12px;
          justify-content: center;
          align-items: center;
          gap: 6px;
          border-radius: 32px;
          background: $gray-5;
          cursor: pointer;

          .tab-chevron {
            color: $gray-70;
            width: 18px;
            height: 18px;
          }
        }

        .dropDownFilter {
          position: absolute;
        }

        button {
          color: $blue-40;
        }

        .clear-filters {
          display: flex;
          align-items: center;
          line-height: 1;
        }
      }

      .dropdown-chevron {
        transform: translateX(-140%) translateY(50%);
      }
    }
  }

  mat-accordion {
    margin-block-end: 1em;
  }

  mat-expansion-panel {
    background: $gray-5;
    box-shadow: none;
  }

  mat-panel-title {
    gap: 8px;

    app-icon {
      min-width: 2em;
    }
  }

  mat-expansion-panel-content, mat-expansion-panel-body {
    height: 24px !important;
  }

  .main-content {
    height: calc(100vh - $header-bar-height);

    .cdk-virtual-scroll-viewport {
      @media screen and (max-width: $phone-max-width) {
        width: 100vw;
      }
    }

    .opportunity-grid {
      --grid-columns: auto-fill;

      display: grid;
      grid-template-columns: repeat(var(--grid-columns), minmax(20em, 1fr));
      gap: 1em;
      padding: 0 1em 1em;

      &.grid-column-1 {
        --grid-columns: 1;
      }

      @media screen and (min-width: $tablet-max-width) {
        --grid-columns: 3;
      }
    }

    .side-pane {
      min-width: 414px;
      align-self: stretch;
      background-color: $gray-0;
      overflow-y: auto;

      &.full-width {
        width: 100% !important;
      }
    }

    .map {
      width: 100%;
    }
  }

  &.impersonating {
    .main-content {
      height: calc(100vh - $header-bar-height - $impersonating-bar-height);
    }
  }

  .results-list {
    height: 100%;
  }

  .filter-button-wrapper {
    display: none;
  }

  @media (max-width: $mobile-breakpoint) {
    .filters {
      padding: 16px 24px 16px;

      .tabs {
        padding-block-end: 1em;
        overflow-x: scroll;
        scrollbar-color: $blue-40 transparent;

        // For IE and Edge
        -ms-overflow-style: scrollbar;

        // For Firefox
        scrollbar-width: thin;

        // For Chrome and Safari
        &::-webkit-scrollbar {
          width: .25em;
        }
      }

      #offCampusOnlyToggle {
        width: 100%;

      }

      .search-input-wrapper {
        flex: 1;
        max-width: initial;
      }

    }

    .side-pane {
      min-width: 100%;
      // For IE and Edge
      -ms-overflow-style: none;

      // For Firefox
      scrollbar-width: none;

      // For Chrome and Safari
      &::-webkit-scrollbar {
        width: 0;
      }

    }

    .search-input-wrapper {
      max-width: 162px;
      margin-right: -10px;

      .search-input {
        max-width: initial;
      }
    }

    .dropdown-wrapper {
      max-width: 164px;
    }

    .filter-button-wrapper {
      display: block;
      padding: 10px;
      border: solid 1px $gray-20;
      border-radius: 6px;
      width: fit-content;
    }

    .filter-icon {
      height: 24px;
      width: 24px;
    }

    .filter-wrapper {
      width: 100%;
      overflow-x: scroll;
    }

    .input-wrapper {
      display: flex;
      width: 100%;

      input {
        flex: 1;
      }
    }
  }
}

// Search Opportunity Toggle
@mixin searchOpportunityToggle {
  $optionBorderRadius: 2em;
  $optionGap: 1em;

  & {
    --optionWidth: 10em;
    width: fit-content;
    isolation: isolate;

    @media screen and (max-width: $mobile-breakpoint) {
      width: 100% !important;
    }
  }

  label {
    @include cssReset;
  }

  *:not(.search-opportunity-toggle__container *) {
    width: 100%;
  }

  .search-opportunity-toggle__container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border: none;
    border-radius: $optionBorderRadius;
    background-color: $gray-10;
    height: 3em;

    .search-opportunity-toggle__slider {
      position: absolute;
      top: 0;
      left: 0;
      width: var(--optionWidth);
      height: 100%;
      border-radius: $optionBorderRadius;
      background-color: $blue-40;
      z-index: 2;
      transform: translateX(100%);
      transition: transform 100ms ease-in-out;

      @media screen and (max-width: $mobile-breakpoint) {
        --optionWidth: 50%;
      }

      &.--offCampus {
        transform: translateX(0%);
      }
    }

    .search-opportunity-toggle__option {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: .5em;
      flex: 1;
      width: var(--optionWidth);
      border-radius: $optionBorderRadius;
      background-color: $gray-10;
      transition: background-color 100ms ease-in-out;
      padding: 8px 12px;

      &:hover,
      &:focus {
        background-color: darken($gray-10, 5%);
        transition: background-color 0ms ease-in-out;
      }

      &.--enabled-option {
        color: white;

        .search-opportunity-toggle__icon,
        .search-opportunity-toggle__label {
          color: currentColor;
          transition: color 100ms ease-in-out;
        }

      }

      .search-opportunity-toggle__icon {
        display: flex;
        width: 2em;
        height: 2em;
        user-select: none;
        z-index: 2;

        @media screen and (min-width: 220px) and (max-width: 300px) {
          display: none;
        }
      }

      .search-opportunity-toggle__label {
        display: flex;
        user-select: none;
        z-index: 2;
        white-space: nowrap;

        @media screen and (max-width: 220px) {
          display: none;
        }
      }
    }
  }
}
