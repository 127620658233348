
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

/* Override bootstrap theme variables */
@import "./sass/themes";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.all-component-typographies();
@include mat.elevation-classes();
@include mat.app-background();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$serve-ui-primary: mat.m2-define-palette(mat.$m2-blue-palette);
$serve-ui-accent: mat.m2-define-palette(mat.$m2-indigo-palette, A400);

// The warn palette is optional (defaults to red).
$serve-ui-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Custom typography
$custom-typography: mat.define-typography((
  plain-family: $font-family-lato,
  brand-family: $font-family-lato,
));

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$serve-ui-theme: mat.m2-define-light-theme((
  color: (
    primary: $serve-ui-primary,
    accent: $serve-ui-accent,
    warn: $serve-ui-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($serve-ui-theme);
@include mat.typography-hierarchy($custom-typography);

/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;

  font-family: $font-family-lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  &.mat-typography {
    letter-spacing: unset !important;
  }
}


/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

/* Importing Mapbox*/
@import 'mapbox-gl/dist/mapbox-gl.css';

/* Custom Mat-checkbox styles */
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
    background: $blue-50 !important;
    border-color: $blue-50 !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    border: 1px solid $gray-40 !important;
    border-radius: 25%;
}

label {
  cursor: pointer;
}

/* Custom Inputs */

.input-wrapper {
  color: $gray-80;
  font-family: 'Lato', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.search-input-wrapper {
  width: 343px;
  align-items: flex-start;
  gap: 4px;

  .search-wrapper {
    display: flex;
    height: 48px;
    padding: 12px 8px;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid $gray-30;
    align-items: center;
    width: 100%;

    app-icon {
      top: 2px;
    }

    .search-input {
      padding: 0;
      height: 46px;
      border: none;
    }
  }
}

.custom-input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 8px 12px 12px;
  width: 100%;
  max-width: 320px;
  height: 48px;
  background: $white;
  border: 1px solid $gray-20;
  border-radius: 4px;
  font-size: 16px;
}

.custom-link {
  &:link, a {
    color: $blue-40;
    text-decoration: none;
    font-weight: 700;
  }
}

.custom-input:focus{
  outline: none !important;
  border-color: $blue-50;
}

.custom-input-error{
  border: 1px solid $red-40 !important;
}

.custom-input-error:focus{
  outline: none !important;
  border-color: $red-40;
}

.custom-input-success{
  border: 1px solid $green-50 !important;
}

.custom-input-success:focus{
  outline: none !important;
  border-color: $green-50;
}

.custom-radio-input {
  isolation: isolate;

  input[type="radio"] {
    $buttonSize: 24px;

    position: relative;
    width: $buttonSize;
    height: $buttonSize;
    aspect-ratio: 1 / 1;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      inset: 50% 0 0 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      padding: 2em;
      z-index: -1;
    }
  }

  label {
    width: 100%;
  }

  input, label {
    margin: 0;
  }
}

.radio-active {
  border-radius: 6px;
  border: 2px solid $blue-40;
  color: $blue-40;
  background: $blue-0;

  input[type=radio] {
    accent-color: $blue-40;
  }
}

.custom-auto-complete {
  background-color: $blue-0 !important;
}

.required-field::after {
  content: "*";
  color: red;
}

.inputErrorMessage {
  color: $red-40;
}

/* Custom Buttons */
[class*="custom-button"] {
  white-space: $btn-white-space;
}

.btn-primary {
  background-color: $blue-60 !important;

  &[disabled] {
    background-color: $gray-40 !important;
    border: none !important;
  }

  &:hover {
    background-color: $blue-80 !important;
  }
}

.btn-tall {
  height: 48px;
}

.custom-button-small-primary-dark-blue {
  height: 33px;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid $gray-20;
  background: $blue-60;
  color: $white;
}

.custom-button-small-secondary {
  display: flex;
  height: 33px;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid $gray-20;
  background: $white;
}

.custom-button-small-primary-red {
  height: 33px;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid $gray-20;
  background: $red-50;
  color: $white;

  &:disabled {
    background-color: $gray-20 !important;
    border: none !important;
    color: $gray-40;
  }
}

.custom-button-large-primary, .custom-button-large-primary-dark-blue,
.custom-button-large-primary-red, .custom-button-large-primary-admin-blue {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  height: 48px;
  border-radius: 6px;
  border: none;
  color: white;
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-family: $font-family-lato;
}

.custom-button-large-primary-red {
  background: $red-50;
}

.custom-button-large-primary {
  background: $blue-40;
}

.custom-button-large-primary:focus, .custom-button-small-primary:focus  {
  background: $blue-40;
  box-shadow: 0 0 0 4px $blue-40, 0 0 0 2px #FFF;
  border: none;
}

.custom-button-large-primary:focus-visible, .custom-button-small-primary:focus-visible {
  outline: $white auto 1px;
}

.custom-button-large-primary:disabled, .custom-button-large-primary-dark-blue:disabled,
.custom-button-small-primary:disabled, .custom-button-large-primary-red:disabled,
.custom-button-large-secondary:disabled, .custom-button-small-secondary:disabled {
  background: $gray-10;
  color: $gray-30;
}

.custom-button-large-primary-dark-blue {
  background: $blue-60;
}

.custom-button-large-primary-admin-blue {
  background: $blue-80;
}

.custom-button-large-primary:not([disabled]):hover, .custom-button-large-primary-dark-blue:not([disabled]):hover,
.custom-button-large-primary-red:not([disabled]):hover, .custom-button-small-primary-dark-blue:not([disabled]):hover {
  box-shadow: 0 2px 7px rgba(9, 104, 217, 0.2), 0 1px 4px rgba(10, 23, 51, 0.27);
}

.custom-button-large-secondary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  height: 48px;
  left: 40px;
  top: 80px;
  background: $white;
  border: 1px solid $gray-20;
  border-radius: 6px;
  text-decoration: none;
  font-family: $font-family-lato;
}

.custom-button-large-secondary:not([disabled]):hover, .custom-button-small-secondary:not([disabled]):hover {
  background: rgba(0, 65, 97, 0.02);
}

.custom-button-large-secondary:not([disabled]):active,.custom-button-small-secondary:not([disabled]):active  {
  background: $gray-5;
}


/* Custom Tags */
.mat-mdc-chip {
  padding: 10px 8px 10px 8px;
  gap: 6px;
  background-color: $blue-40 !important;
  color: $white;
  --mdc-chip-label-text-color: $white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: $white;
}

.mat-mdc-chip-remove {
  opacity: .80 !important;
}

/* Custom Tabs */

.tab-state {
  display: flex;
  padding: 24px 0;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: $gray-50;
  text-decoration: none !important;
}

.tab-state:hover {
  color: $blue-60;
  cursor: pointer;
  text-decoration: none;
}

.active-tab {
  color: $blue-60;
  border-bottom: 3px solid $blue-60;
  text-decoration: none;
}

.inactive-tab {
  color: $gray-50;
  border-bottom: 3px solid $white;
}

.active-page {
  background: $gray-10 !important;
}

.admin-active-page {
  background: $blue-70 !important;
}


/* Custom Dropdowns */
select {
  display: none; /*hide original SELECT element: */
}

.custom-dropdown {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 8px 12px 12px;
  width: 160px;
  height: 48px;
  background: $white;
  border: 1px solid $gray-20;
  border-radius: 4px;
  -webkit-appearance: none;
}

.custom-dropdown-small {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 45px;
  padding-left: 8px;
  margin-right: -24.5px;
  height: 30px;
  background: $white;
  border: 1px solid $gray-20;
  border-radius: 6px;
  -webkit-appearance: none;
}

.dropdown-wrapper {
  display: flex;
  flex-direction: row;
}

.dropdown-wrapper-small {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dropdown-chevron {
  pointer-events: none;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(-50%) translateY(50%);
  font-family: $font-family-lato;
}

.dropdown-chevron-small {
  pointer-events: none;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 0;
  top: 0;
}

mat-select-arrow {
  width: 24px !important;
  height: 24px !important;
}

.custom-dropdown:focus {
  outline: none !important;
  border-color: $blue-50;
}

/* Custom Likert Table */

.likert-table {
  gap: 48px;

  .title-and-desc {
    gap: 16px;
  }

  legend {
    align-items: flex-end;
    gap: 8px;
    border-bottom: 1px solid $gray-10;
    padding-bottom: 24px;

    .legend-row {
      display: flex;
      width: 486px;
      align-items: flex-end;

      div {
        flex: 1 0 0;
        text-align: center;
      }
    }
  }

  tr {
    display: flex;
    padding: 24px 0;
    gap: 40px;
    border-bottom: 1px solid $gray-10;

    .small-bold-text {
      flex: 1;
    }

    ul {
      width: 486px;
      list-style-type: none;
      padding: 0;

      li {
        display: flex;
        justify-content: center;
        gap: 6px;
        flex: 1 0 0;
      }
    }
  }
}


/* Custom Mat-Stepper Styles */
////Inactive Step Icon
//.mat-step-header .mat-step-icon {
//  background-color: $gray-50;
//
//  .icon-content {
//    color: red;
//  }
//}

//Active Step Icon
.mat-step-header .mat-step-icon-selected, {
  background-color: $blue-40 !important;
}


.select_arrow {
  background: inherit;
  background-color: $white;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 35px;
  height: calc(100% - 2px);
  pointer-events: none;

  &:before {
    content: ' ';
    position: absolute;
    top: 14px; //16px
    right: 16px;
    width: 9px;
    height: 9px;
    border: solid $gray-10;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    --webkit-transform: rotate(45deg);
  }
}

/* Icons */
.xx-small-icon {
  width: 16px;
  height: 16px;
}

.x-small-icon {
  width: 18px;
  height: 18px;
}

.small-icon {
  width: 20px;
  height: 20px;
}

.medium-icon {
  width: 22px;
  height: 22px;
}

.large-icon {
  width: 24px;
  height: 24px;
}

.xxxxl-icon {
  width: 32px;
  height: 32px;
}

/* Custom Text */

mat-error {
  color: red;
}

.underline {
  cursor: pointer;
}

.underline:hover {
  text-decoration: underline;
}

.line-break {
  white-space: pre-wrap;
}

.xx-small-medium-text {
  /* XX-Small (12) - Medium */
  font-family: Lato, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}

.xx-small-bold-text {
  /* XX-Small (12) - Bold */
  font-family: Lato, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 142.857% */
}

.xx-small-gray-text {
  /* XX-Small (12) - Gray Uppercase*/
  color: $gray-50;
  font-family: Lato, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  text-transform: uppercase;
}

.x-small-bold-text {
  /* X-Small (14) - Bold */
  font-family: Lato, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

.x-small-reg-text {
  /* X-Small (14) - Regular */
  font-family: Lato, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.x-small-link {
  /* X-Small (14) - Link */
  color: $gray-50;
  font-family: Lato, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
}

.x-small-gray-text {
  /* X-Small (14) - Link */
  color: $gray-50;
  font-family: Lato, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.x-small-italic {
  font-family: Lato, serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.x-small-medium-text {
  /* X-Small (14) - Medium */
  font-family: Lato, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.small-reg-text {
  /* Small (16) - Regular */
  font-family: Lato, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.small-gray-text {
  /* Small (16) - Gray */
  color: $gray-50;
  font-family: Lato, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.small-bold-text {
  /* Small (16) - Bold */
  font-family: Lato, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.small-medium-text {
  /* Small (16) - Medium */
  font-family: Lato, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.small-email-text {
  color: $gray-50;
  font-family: Lato, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-decoration-line: underline;
}

.small-link {
  /* Small (16) - Link */
  color: $gray-50;
  font-family: Lato, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-decoration-line: underline;
}

.medium-regular-text {
  /* Medium (20) - Regular */
  font-family: Lato, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
}

.medium-medium-text {
  /* Medium (20) - Medium */
  font-family: Lato, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.medium-bold-text {
  /* Medium (20) - Bold */
  font-family: Lato, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
}

.medium-link-text {
  color: $gray-50;
  font-variant-numeric: lining-nums tabular-nums;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Lato, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-decoration-line: underline;
}

.large-bold-text {
  /* Large (24) - Bold */
  font-family: Lato, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
}

.x-large-bold-text {
  /* Large (24) - Bold */
  font-family: Lato, serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 133.333% */
}

.x-large-x-bold-text{
  /* X-Large (32) - ExtraBold */
  font-family: Lato, serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px; /* 125% */
}

.xx-large-x-bold-text {
  color: $blue-90;
  font-size: 48px;
  font-family: Lato, serif;
  font-weight: 800;
  line-height: 56px;
}

.back-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 6px;
  color: $blue-40;

  :hover {
    cursor: pointer;
  }
}

/* Custom styling options */

.no-scroll {
  overflow: hidden;
}

.f-col {
  display: flex;
  flex-direction: column;
}

.f-r-col {
  display: flex;
  flex-direction: column-reverse;
}

.f-row {
  display: flex;
  flex-direction: row;
}

.f-r-row {
  display: flex;
  flex-direction: row-reverse;
}

/* Custom Map Styling */
#mapContainer {
  position: relative;
  isolation: isolate;

  .interaction-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  .interaction-segment {
    // Comment the line below to hide the interaction segments
    //border: 1px solid red;
  }

}


.mapboxgl-popup-tip {
  display: none;
}

.mapboxgl-popup {
  display: flex;
  background: gray;
  flex-direction: column;
  border-radius: 4px;
  background: $white;
  box-shadow: 0 0.2992px 2.5984px 0 rgba(0, 0, 0, 0.01), 0 1.3376px 6.0032px 0 rgba(0, 0, 0, 0.02), 0 3.3264px 13.7088px 0 rgba(0, 0, 0, 0.02), 0 6.4768px 29.2096px 0 rgba(0, 0, 0, 0.03), 0 11px 56px 0 rgba(0, 0, 0, 0.04);
  max-height: 100px;

  @media screen and (max-width: $tablet-max-width) {
    border-radius: 4px;
    background: unset;
    box-shadow: unset;
  }

  @media screen and (max-width: $phone-sm-width) {
    align-items: center;
  }

  .popup-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .org-popup-logo {
    width: 36px;
    height: 36px;
    border-radius: 55px;
  }

  .popup-heading {
    gap: 4px;

    .main-heading {
      max-height: 60px;
      overflow-y: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  .chevron-right-icon {
    width: 36px;
    height: 36px;
    color: black;
    cursor: pointer;
  }


  .mapboxgl-popup-content {
    width: 100%;
    padding: 8px !important;

    @media screen and (max-width: $phone-sm-width) {
      max-width: calc(100% - 1em);
    }
  }
}

//Datepicker styles
.mat-calendar-body-selected {
  background-color: $blue-40 !important;
}

.mat-calendar-body-label:not(:last-of-type) {
  visibility: hidden;
}
.mat-calendar-body-label:last-of-type {
  display: none;
}

.mat-datepicker-toggle-active {
  color: $blue-40 !important;
}

//Modal Styles
.modal { opacity: 1; }

.modal-content {

  .btn-danger {
    background-color: $red-50;

    &[disabled] {
      background-color: $gray-40;
      border: none;
    }
  }

  .btn-link {
    color: $blue-40;
    text-decoration: none;

    &:hover, &:focus {
      color: lighten($blue-40, 10%);
      text-decoration: underline;
    }
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 800;
}

.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 801;
  border-radius: 16px;
  background: $white;
  box-shadow: 0 0.29919999837875366px 2.598400115966797px 0 rgba(0, 0, 0, 0.01), 0 1.3375999927520752px 6.003200054168701px 0 rgba(0, 0, 0, 0.02), 0 3.3264000415802px 13.708800315856934px 0 rgba(0, 0, 0, 0.02), 0 6.476799964904785px 29.2096004486084px 0 rgba(0, 0, 0, 0.03), 0 11px 56px 0 rgba(0, 0, 0, 0.04);
  width: calc(100% - 1em);
  max-width: 400px;

  .content {
    width: 100%;
    padding: 32px;
    gap: 24px;

    @media screen and (max-width: $phone-max-width) {
      padding: 1em !important;
    }

    form {
      width: 100%;
    }
  }

  .actions {
    padding-top: 16px;
    gap: 12px;

    @media screen and (max-width: $phone-max-width) {
      flex-direction: column;
    }

    button {
      flex: 1;
      gap: .5em;

      @media screen and (max-width: $phone-max-width) {
        width: 100% !important;
      }
    }
  }

  .closeBtn {
    background: none;
    border: none;
    position: absolute;
    right: 8px;
    top: 8px;
    color: $gray-40;
  }
}

// Custom Expansion Panel
mat-expansion-panel {

  &.custom-expansion-panel {

    .mat-expansion-panel-header {
      height: fit-content !important;
      padding: 1em;
    }

    .mat-expansion-panel-body {
      padding: 0 1em 1em;
      margin-block-start: -.5em;
    }

  }

}

// Custom Container Variables

.config-page-header { //Used on the opportunity and organization config components
  padding: 4rem 4rem 2rem 4rem;
}

@import "app/feature/search-opportunity/search-opportunity.component.scss";
@import "sass/utility-mixins";
// Custom Search Opportunity Toggle
mat-slide-toggle {
  &.search-opportunity-toggle {
    width: 100%;

    @include cssReset;
    @include searchOpportunityToggle;

    .mdc-label {
      display: flex;
      height: 100%;
    }

    .mdc-switch {
      display: none;
    }

  }
}

.cdk-overlay-container {
  z-index: 3080;

  .cdk-overlay-pane {
    width: auto !important;
    max-width: 100%;

    .mat-mdc-autocomplete-panel {
      padding: 0;
    }

  }
}
